
.list-container {
    overflow-y: auto; /* Habilitar el scroll vertical */
  }
  
  .list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .list-item {
    height: auto; /* Permitir que los elementos tengan alturas variables */
  }

  .MiLista {
    display: flex;
    flex-direction: column;
  }
  
  .MiLista > :nth-child(-n+3) {
    flex: 1;
  }